"use client";

import styles from "./App.module.css";
import { collection, addDoc } from "firebase/firestore";
import { db } from "./firebase";
import React from "react";

export default function App() {
  // import TitleBackground from "public/svg/background/title-background.svg";
  const [error, setError] = React.useState(false);
  const dialog = React.useRef(null);

  const onSubmit = async (e) => {
    e.preventDefault();
    const email = document.getElementById("email").value;
    // email has dot and @
    if (!email.includes(".") || !email.includes("@")) {
      setError(true);
      return;
    } else {
      setError(false);
    }

    const docRef = await addDoc(collection(db, "signupemails"), {
      email,
    });
    console.log("Document written with ID: ", docRef.id);
    dialog?.current?.showModal();
  };

  return (
    <div className={styles.TitleSlide}>
      <div>
        <img
          src="/title-background.svg"
          alt="title background"
          width={1920}
          height={1080}
          className={styles.background}
        />
      </div>
      <div className={styles.top}>
        <img src="/logo-white.png" alt="logo" width={320} height={164} />
        <img src="/logo-orange.png" alt="logo" width={320} height={164} />
      </div>
      <div className={styles.title}>
        <h1>Digital Stag & Doe</h1>
        <h3>Wedding, Honeymoon, and Community Fundraising</h3>
        <h2>GAMIFIED FUNDRAISING</h2>
      </div>
      <div className={styles.buttonContainer}>
        {error && (
          <span className={styles.error} id="error">
            Please enter a valid email address
          </span>
        )}
        <input
          type="text"
          placeholder="Enter your email address"
          className={styles.emailInput}
          id="email"
        />
        <button className={styles.joinButton} onClick={onSubmit}>
          NOTIFY ME
        </button> 
      </div>
      <h2>Coming Soon September...</h2>

      <dialog className={styles.dialog} ref={dialog}>
        <h1>Thank you for signing up!</h1>
        <p>
          We will notify you when the site is live and ready for you to
          experience.
        </p>
        <button
          className={styles.closeButton}
          onClick={() => dialog?.current?.close()}
        >
          CLOSE
        </button>
      </dialog>
    </div>
  );
}
