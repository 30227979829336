// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
// firestore sdk v9
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDr-TjsnBUYRZ1RGhpV-T3DNVZk7x1cvUk",
  authDomain: "friendlypotz.firebaseapp.com",
  projectId: "friendlypotz",
  storageBucket: "friendlypotz.appspot.com",
  messagingSenderId: "329180779705",
  appId: "1:329180779705:web:6500b9242c23cb6e223768",
  measurementId: "G-KQ5P5L5S41",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// export const analytics = getAnalytics(app);
export const db = getFirestore(app);
